.about {
    margin-top: 30px;
}

.infSection {
    box-sizing: border-box;
    padding-bottom: 20px;
    border-bottom: 2px solid #E0E0E0;
}

.infSection:last-child {
    margin-top: 30px;
    /* border: none; */
}

.info {
    font-family: 'Montserrat', sans-serif;
    margin: 10px 0 0 0;
}

.title {
    margin: 0;
    font-family: 'Comfortaa', sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
    margin: 0;
    box-sizing: border-box;
    padding-top: 30px;
}

.listItem {
    background-color: #e7e7e7;
    box-shadow: 0 0 10px 5px #dddddd;
    border-radius: 5px;
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px 0 10px;
    font-family: 'Comfortaa', sans-serif;
    font-weight: 700;
    flex-basis: 20%;
}

.uppercase {
    text-transform: uppercase;
}

.text {
    margin: 16px 0 16px 0;
}

@media screen and (max-width: 940px) {
    .listItem {
        flex-basis: 40%;
    }
}

@media screen and (max-width: 530px) {
    .list {
        gap: 16px;
        padding-bottom: 10px;
    }

    .listItem {
        box-shadow: 0 0 5px 5px #dddddd;
    }

    .listItem {
        flex-basis: 100%;
    }
}