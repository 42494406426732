.list {
    display: flex;
    gap: 16px;
    list-style: none;
}

.link {
    font-family: 'Comfortaa', sans-serif;
    color: black;
    text-decoration: none;
    box-sizing: border-box;
    padding-bottom: 3px;
    font-size: 18px;
    font-weight: 500;
}

.link:hover {
    opacity: 0.7;
}

.activeLink {
    border-bottom: 1px solid black;
    font-weight: 700;
    font-size: 20px;
}

@media screen and (max-width: 750px) {
    .navigation {
        display: none;
    }
}