.photoCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 395px;
    background-color: #ddddddaf;
    box-shadow: 0 0 10px 5px #dddddd;
    border: 2px solid #dddddd;
}

.photoPlace {
    height: 198px;
    width: 198px;
    border-radius: 50%;
    background-color: #EEEEEE;
    margin: 30px auto 32px auto;
    overflow: hidden;
}

.photo {
    margin-left: 21px;
    width: 80%;
    height: 110%;
}

.title {
    font-family: 'Comfortaa', sans-serif;
    font-weight: 700;
    font-size: 22.75px;
    margin: 0 0 16px 0;
}

.description {
    font-family: 'Comfortaa', sans-serif;
    font-weight: 300;
    font-size: 16px;
    margin: 0;
    text-align: center;
}

/* @media screen and (max-width: 991px) {
    .photoCard {
        width: 100%;
    }
} */