@font-face {
    font-family: 'Pacifico';
    font-weight: 400;
    font-style: normal;
    src: url('./pacifico/Pacifico-Regular.woff') format('woff'),
        url('./pacifico/Pacifico-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Comfortaa';
    font-weight: 700;
    font-style: normal;
    src: url('./comfortaa/Comfortaa-Bold.woff') format('woff'),
        url('./comfortaa/Comfortaa-Bold.woff2') format('woff2');
}

@font-face {
    font-family: 'Comfortaa';
    font-weight: 300;
    font-style: normal;
    src: url('./comfortaa/Comfortaa-Light.woff') format('woff'),
        url('./comfortaa/Comfortaa-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Comfortaa';
    font-weight: 500;
    font-style: normal;
    src: url('./comfortaa/Comfortaa-Medium.woff') format('woff'),
        url('./comfortaa/Comfortaa-Medium.woff2') format('woff2');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    font-style: normal;
    src: url('./monsterrat/Montserrat-Regular.woff') format('woff'),
        url('./monsterrat/Montserrat-Regular.woff2') format('woff2');
}