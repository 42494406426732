.header {
    /* display: flex; */
    /* justify-content: end; */
    max-width: 1100px;
    box-sizing: border-box;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    /* padding-left: 254px; */
}

.wrapper {    
    /* width: 100%; */
    /* max-width: 808px; */
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 40px;
    height: 46px;
}

.logoContainer {
    display: flex;
    gap: 10px;
    text-decoration: none;
    color: black;
}

.logoContainer:hover {
    opacity: 0.7;    
}

.title {
    font-family: 'Pacifico', sans-serif;
    font-size: 26px;
    margin: 0;
}

@media screen and (max-width: 991px) {
    .header {
        max-width: 991px;
        padding-left: 0;
        justify-content: center;
    }

    .wrapper {
        max-width: 991px;
    }
}

@media screen and (max-width: 450px) {
    .header {
        padding-left: 20px;
        padding-right: 20px;
    }

    .title {
        font-size: 22px;
    }

    .logo {
        width: 32px;
        height: 36px;
    }
}