.main {
    display: flex;
    flex-direction: column;
    /* gap: 36px; */
    margin: 0 auto;
    min-height: 85vh;
    max-width: 1100px;
}

/* @media screen and (max-width: 991px) {
    .main {
        flex-direction: column;
        gap: 0;
    }
} */