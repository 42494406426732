.contacts {
    font-family: 'Comfortaa', sans-serif;
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 20px;
    margin: 0 auto;
    border-bottom: 2px solid #E0E0E0;
    color: black;
}

.title {
    margin: 0;
    display: inline;
}

.list {    
    text-decoration: none;
    font-weight: 500;
    list-style: none;
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding: 0;
    margin: 10px 0 0 0;
}

.listItem {
    display: flex;
    align-items: center;
    gap: 5px;
    box-sizing: border-box;
    padding: 0 8px 0 8px;
    background-color: #e7e7e7;
    box-shadow: 0 0 10px 5px #dddddd;
    border-radius: 5px;
}

.link {
    display: flex;
    text-decoration: none;
    color: black;
    align-items: center;
    gap: 5px;
    margin: 16px 0 16px 0;
}

.icon {
    height: 22px;
    width: 22px;
}

@media screen and (max-width: 940px) {
    .list {
        flex-direction: column;
        gap: 16px;
    }

    .listItem {
        box-shadow: 0 0 5px 5px #dddddd;
    }
}