.portfolio {
    margin-top: 30px;
    box-sizing: border-box;
    padding-bottom: 20px;
}

/* @media screen and (max-width: 1150px) {
    .portfolio {
        margin-left: auto;
        margin-right: auto;
    }
} */

.title {
    margin: 0;
    font-family: 'Comfortaa', sans-serif;
    font-size: 24px;
    font-weight: 700;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
    margin-top: 30px;
    font-family: 'Comfortaa', sans-serif;
}

.listItem {
    width: 100%;
}

.project {
    display: flex;
    width: 100%;
    background-color: #e7e7e7;
    border-radius: 20px;
    box-shadow: 0 0 10px 8px #dddddd;
    display: flex;
}

@media screen and (max-width: 950px) {
    .project {
        flex-direction: column;
    }
}

.preview {
    max-width: 420px;
    width: 100%;
    max-height: 340px;
    height: 100%;
    border-radius: 20px;
    align-self: center;
}

.cardTitle {
    margin: 0;
    font-size: 20px;
}

.description {
    box-sizing: border-box;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
}

.text {
    /* font-size: 15px; */
}

.textAbout {
    margin: 20px 0 20px 0;
}

@media screen and (max-width: 670px) {
    .textAbout {
        font-size: 14px;
    }

    .cardTitle {
        font-size: 18px;
    }
}

.links {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.link {
    color: black;
    text-decoration: none;
    border-bottom: 1px solid #c5c5c5;
    box-sizing: border-box;
    padding-bottom: 3px;
    display: flex;
    justify-content: space-between;
}

.linkIcon {
    width: 24px;
}

.link:hover {
    opacity: 0.7;
}