.App {
  /* max-width: 1100px; */
  min-width: 320px;
  margin: 0 auto;
  box-sizing: border-box;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

@media screen and (max-width: 450px) {
  .App {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}