.infoSection {
    /* max-width: 808px; */
    width: 100%;
    background-color: white;
    border: 2px solid rgb(221, 221, 221);
    box-shadow: 0 0 10px 5px rgba(221, 221, 221, 1);
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 50px;
    background: url('../../images/page-background.jpg') no-repeat center;
    background-size: cover;
}

@media screen and (max-width: 991px) {
    .infoSection {
        max-width: 991px;
        border-top: none;
        height: 100%;
    }
}

@media screen and (max-width: 550px) {
    .infoSection {
        padding-left: 20px;
        padding-right: 20px;
    }
}